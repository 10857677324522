import { css } from '@emotion/react';

import colors from '../../../configs/colors';

export const tableContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0 0 0;
`;

export const tableOverflowStyles = css`
  width: 100%;

  @media screen and (max-width: 767px) {
    overflow-x: scroll;
  }
`;

export const tableStyles = css`
  width: 100%;
  border: 1px solid ${colors.WHITE};
  background-color: ${colors.WHITE};

  @media screen and (max-width: 767px) {
    width: 1200px;
  }
`;

export const tableHeaderContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const tableHeaderStyles = (sort, ratio, isColumnSortActive) => css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${100 * ratio}%;
  height: 60px;
  padding: 20px 10px;
  border-bottom: 1px solid ${colors.LIGHT_GREY};
  outline: none;
  cursor: ${sort ? 'pointer' : 'default'};

  span {
    font-weight: bold;
    color: ${isColumnSortActive ? colors.PRIMARY_ORANGE : colors.BLACK};
    user-select: none;
  }

  &.center {
    justify-content: center;
  }

  &:first-of-type {
    padding: 20px 0 20px 30px;
  }

  &:last-of-type {
    padding: 20px 30px 20px 0;
  }
`;

export const tableBodyContainerStyles = css`
  position: relative;
  min-height: 500px;
`;

export const tableDataContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;

  span, p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 1.5;
    color: ${colors.BLACK};
    overflow: hidden;
  }

  &:hover {
    background-color: ${colors.PRIMARY_ORANGE};

    a,
    span,
    i {
      color: ${colors.WHITE};
    }
  }

  &:nth-of-type(even) {
    background-color: ${colors.LIGHT_GREY};

    &:hover {
      background-color: ${colors.PRIMARY_ORANGE};

      a,
      span,
      i {
        color: ${colors.WHITE};
      }
    }
  }
`;

export const tableDataStyles = (ratio) => css`
  display: flex;
  align-items: center;
  width: ${100 * ratio}%;
  height: 60px;
  padding: 0 10px;

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }

  &:first-of-type {
    padding: 0 0 0 30px;
  }

  &:last-of-type {
    padding: 0 30px 0 0;
  }
`;

export const linkStyles = css`
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  color: ${colors.PRIMARY_BLUE};
`;
