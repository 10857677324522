export default [
  { label: 'Part Time Program', value: 'part_time' },
  { label: 'Full Time Program', value: 'full_time' },
  { label: 'Prakerja', value: 'prakerja' },
  { label: 'Trial Prakerja', value: 'trial_prakerja' },
  { label: 'Kode', value: 'kode' },
  { label: 'Kampus Merdeka', value: 'kampus_merdeka' },
  { label: 'Study Independent', value: 'study_independent' },
  { label: 'Spark AR', value: 'spark' },
  { label: 'Kidz', value: 'kidz' },
];
