import { userService } from '../services';

const introspection = () => {
  const credentials = JSON.parse(localStorage.getItem('credentials'));

  return userService.post(
    '/auth/introspection',
    {
      access_token: credentials.access_token,
    },
    {
      headers: {
        Authorization: `Bearer ${credentials.access_token}`,
      },
    },
  );
};

export default introspection;
