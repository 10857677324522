import { useContext, useState, useEffect } from 'react';

import Layout from '../../components/layout/Layout';

import { Tags } from '../../components/pages';
import { Button } from '../../components/general';
import { LoadingContext } from '../../contexts/loadingContext';
import { BannerContext } from '../../contexts/bannerContext';
import { isLoading, isNotLoading } from '../../reducers/loadingReducers';
import { isError, isSuccess } from '../../reducers/bannerReducers';
import { syncCourses, coursePrices } from '../../api/get';
import { completionTypeList } from '../../constant';
import { updateTag } from '../../api/put';

const TagsPage = () => {
  const loadingContext = useContext(LoadingContext);
  const bannerContext = useContext(BannerContext);
  const [selectedRow, setSelectedRow] = useState({
    id: 0,
    kodeCourseTitle: '',
    courseType: {
      label: '',
      value: '',
    },
  });
  const [courseData, setCourseData] = useState([]);

  const handleSyncCourses = async () => {
    try {
      loadingContext.dispatch(isLoading());
      await syncCourses();
    } catch (error) {
      bannerContext.dispatch(isError(error.response.data.message));
    } finally {
      loadingContext.dispatch(isNotLoading());
    }
  };

  const handleSelectedRow = (id, kodeCourseTitle, courseType) => {
    const selectedCourseTypeOption = completionTypeList.find(
      (d) => d.value === courseType,
    );

    setSelectedRow({
      id,
      kodeCourseTitle,
      courseType: selectedCourseTypeOption,
    });
  };

  const handleSubmit = async () => {
    try {
      loadingContext.dispatch(isLoading());

      const parsedData = {
        tag: selectedRow.courseType.value,
      };

      await updateTag(selectedRow.id, parsedData);
      bannerContext.dispatch(
        isSuccess(
          `${selectedRow.kodeCourseTitle} successfully updated.`,
        ),
      );
    } catch (error) {
      bannerContext.dispatch(isError(error.response.data.message));
    } finally {
      loadingContext.dispatch(isNotLoading());
    }
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        loadingContext.dispatch(isLoading());
        const coursesRes = await coursePrices();
        const parsedCourseData = coursesRes.data.data.map((d) => ({
          id: d.id,
          kode_course_title: d.kode_course_title.toLowerCase(),
          thinkific_course_id: d.thinkific_course_id,
          thinkific_product_id: d.thinkific_course_id,
          course_type: d.course_type,
          action: (
            <Button
              type="button"
              size="S"
              onClick={() => handleSelectedRow(d.id, d.kode_course_title, d.course_type)}
            >
              select
            </Button>
          ),
        }));
        setCourseData(parsedCourseData);
      } catch (error) {
        bannerContext.dispatch(isError(error.response.data.message));
      } finally {
        loadingContext.dispatch(isNotLoading());
      }
    };

    fetchCourses();
  }, []);

  return (
    <Layout>
      <Tags
        handleSyncCourses={handleSyncCourses}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        courseData={courseData}
        handleSubmit={handleSubmit}
      />
    </Layout>
  );
};

export default TagsPage;
