import moment from 'moment';
import { useContext, useState, useEffect } from 'react';

import Layout from '../../components/layout/Layout';
import CreateVoucher from '../../components/pages/CreateVoucher/CreateVoucher';
import { LoadingContext } from '../../contexts/loadingContext';
import { BannerContext } from '../../contexts/bannerContext';
import { isLoading, isNotLoading } from '../../reducers/loadingReducers';
import { isError, isSuccess } from '../../reducers/bannerReducers';
import { courses, bundle } from '../../api/get';
import { createVoucher } from '../../api/post';
import { useIsFirstRender } from '../../hooks';

const CreateVoucherPage = () => {
  const isFirstRender = useIsFirstRender();
  const loadingContext = useContext(LoadingContext);
  const bannerContext = useContext(BannerContext);
  const [courseOptions, setCourseOptions] = useState([]);
  const [bundleOptions, setBundleOptions] = useState([]);
  const [voucherData, setVoucherData] = useState([]);
  const [successData, setSuccessData] = useState({
    status: false,
    vendor: '',
    program: '',
    currentDate: moment(new Date()).format('DD-MM-YYYYTHH:mm:ss'),
  });
  const [formData, setFormData] = useState({
    totalVoucher: '',
    vendor: {
      label: '',
      value: '',
    },
    voucherType: {
      label: '',
      value: '',
    },
    course: {
      label: '',
      value: '',
    },
    bundle: {
      label: '',
      value: '',
    },
    expiryDate: '',
  });

  const handleResetForm = () => {
    setFormData({
      totalVoucher: '',
      vendor: {
        label: '',
        value: '',
      },
      voucherType: {
        label: '',
        value: '',
      },
      course: {
        label: '',
        value: '',
      },
      bundle: {
        label: '',
        value: '',
      },
      expiryDate: null,
    });
  };

  const fetchCourses = async (params) => {
    const coursesRes = await courses(params);
    const options = coursesRes.data.data.map((d) => ({
      label: d.kode_course_title,
      value: d.thinkific_product_id,
    }));

    return options;
  };

  const fetchBundle = async () => {
    const bundleRes = await bundle();
    const options = bundleRes.data.data.map((d) => ({
      label: d.kode_course_title,
      value: d.thinkific_bundle_id,
    }));

    return options;
  };

  const handleSubmit = async () => {
    const isBundle = formData.voucherType.value === 'kode_bundle';
    const type = isBundle ? 'bundle' : 'course';
    const parsedFormData = {
      count: formData.totalVoucher,
      voucher_type: formData.voucherType.value,
      vendor_external_name: formData.vendor.value,
      expired_at: formData.expiryDate ? formData.expiryDate : null,
    };

    parsedFormData[`thinkific_${type}_external_id`] = formData[type].value;

    try {
      loadingContext.dispatch(isLoading());

      const res = await createVoucher(parsedFormData);
      const parsedVoucherData = res.data.vouchers.map((d) => [d]);

      setVoucherData([['Voucher Code'], ...parsedVoucherData]);
      setSuccessData((prevState) => ({
        ...prevState,
        status: true,
        courseName: formData.course.label,
        vendor: formData.vendor.value,
      }));

      bannerContext.dispatch(
        isSuccess(
          `${parsedVoucherData.length} voucher code successfully created.`,
        ),
      );
    } catch (error) {
      bannerContext.dispatch(isError(error.response.data.message));
    } finally {
      handleResetForm();
      loadingContext.dispatch(isNotLoading());
    }
  };

  useEffect(() => {
    const fetchOptions = async () => {
      if (formData.voucherType.value !== 'kode_bundle') {
        const params = {
          course_type: formData.voucherType.value,
        };

        const courseOptionsRes = await fetchCourses(params);

        setCourseOptions(courseOptionsRes);
      }

      if (formData.voucherType.value === 'kode_bundle') {
        const bundleOptionsRes = await fetchBundle();

        setBundleOptions(bundleOptionsRes);
      }
    };

    if (!isFirstRender) {
      try {
        loadingContext.dispatch(isLoading());
        fetchOptions();
      } catch (error) {
        bannerContext.dispatch(isError(error.response.data.message));
      } finally {
        loadingContext.dispatch(isNotLoading());
      }
    }
  }, [formData.voucherType.value]);

  return (
    <Layout>
      <CreateVoucher
        courseOptions={courseOptions}
        bundleOptions={bundleOptions}
        formData={formData}
        setFormData={setFormData}
        voucherData={voucherData}
        successData={successData}
        handleSubmit={handleSubmit}
        handleResetForm={handleResetForm}
      />
    </Layout>
  );
};

export default CreateVoucherPage;
