export default [
  { label: 'JD ID', value: 'jd_id' },
  { label: 'Blibli', value: 'blibli' },
  { label: 'Shopee', value: 'shopee' },
  { label: 'Tokopedia', value: 'tokopedia' },
  { label: 'Cakap', value: 'cakap' },
  { label: 'Mau Belajar Apa', value: 'mau_belajar_apa' },
  { label: 'Pintaria', value: 'pintaria' },
  { label: 'Part Time Program', value: 'part_time' },
  { label: 'Kampus Merdeka', value: 'kampus_merdeka' },
  { label: 'Kode', value: 'kode' },
  { label: 'Spark AR', value: 'spark' },
  { label: 'Dummy', value: 'dummy' },
  { label: 'Coding for kids', value: 'coding_for_kids' },
  { label: 'Internal Use', value: 'internal' },
  { label: 'External Test', value: 'external_test' },
  { label: 'Bukalapak', value: 'bukalapak' },
  { label: 'karier.mu', value: 'karier_mu' },
  { label: 'Pijar Mahir', value: 'pijar_mahir' },
  { label: 'Lazada', value: 'lazada' },
];
