import {
  CreateVoucherPage,
  DashboardPage,
  Error404Page,
  GenerateCertificatePage,
  ISAPage,
  LoginPage,
  PrakerjaFeedbackPage,
  TagsPage,
} from '../pages';

export default [
  {
    path: '/create-voucher',
    component: CreateVoucherPage,
    isPrivate: true,
  },
  {
    path: '/dashboard',
    component: DashboardPage,
    isPrivate: true,
  },
  {
    path: '/generate-certificate',
    component: GenerateCertificatePage,
    isPrivate: true,
  },
  {
    path: '/isa',
    component: ISAPage,
    isPrivate: true,
  },
  {
    path: '/login',
    component: LoginPage,
    isPrivate: false,
  },
  {
    path: '/tags',
    component: TagsPage,
    isPrivate: true,
  },
  {
    path: '/prakerja-feedback',
    component: PrakerjaFeedbackPage,
    isPrivate: true,
  },
  {
    path: '*',
    component: Error404Page,
    isPrivate: false,
  },
];
