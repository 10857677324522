/** @jsxImportSource @emotion/react */
import { useHistory, NavLink } from 'react-router-dom';

import companyLogo from '../../../assets/svg/logo.svg';
import { Link, Button } from '../../general';
import { headerMenu } from '../../../configs';
import { logout } from '../../../api/post';
import {
  navbarStyles,
  navbarContentStyles,
  menuListContainerStyles,
  listStyles,
  linkStyles,
} from './Header.styles';

const Header = () => {
  const history = useHistory();

  const handleLogout = () => {
    logout();
    localStorage.removeItem('email');
    localStorage.removeItem('credentials');
    history.push('/login');
  };

  return (
    <div css={navbarStyles}>
      <div css={navbarContentStyles}>
        <NavLink to="/dashboard">
          <img src={companyLogo} alt="hacktiv8-logo" />
        </NavLink>
        <ul css={menuListContainerStyles}>
          {headerMenu.map(({ label, path }) => (
            <li key={label} css={listStyles}>
              <Link
                to={path}
                styles={linkStyles}
                activeClassName="isMenuActive"
              >
                {label}
              </Link>
            </li>
          ))}
          <li>
            <Button
              type="button"
              size="S"
              onClick={handleLogout}
              color="DANGER_RED"
              inverted
            >
              logout
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
