/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  useState,
  useContext,
  useEffect,
} from 'react';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import { LoadingModal, Text } from '../general';
import { BannerContext } from '../../contexts/bannerContext';
import { bannerStyles, messageStyles, bodyStyles } from './Layout.styles';

const Layout = ({ children, isPaddingActive }) => {
  const { value } = useContext(BannerContext);
  const [bannerShowStatus, setBannerShowStatus] = useState(true);

  useEffect(() => {
    if (value.message) {
      setTimeout(() => {
        setBannerShowStatus(false);
      }, 5000);
    }

    setBannerShowStatus(true);
  }, [value]);

  return (
    <>
      <Header />
      {value.message && bannerShowStatus && (
        <div css={bannerStyles(value.isError)}>
          <Text styles={messageStyles}>{value.message}</Text>
        </div>
      )}
      <div css={bodyStyles(isPaddingActive)}>
        {children}
        <LoadingModal />
      </div>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isPaddingActive: PropTypes.bool,
};

Layout.defaultProps = {
  isPaddingActive: true,
};

export default Layout;
