import Layout from '../../components/layout/Layout';
import { Dashboard } from '../../components/pages';

const DashboardPage = () => (
  <Layout isPaddingActive={false}>
    <Dashboard />
  </Layout>
);

export default DashboardPage;
