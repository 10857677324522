import { ShortText } from '../../general';

export default [
  {
    key: 'no',
    label: 'No',
    ratio: 0.075,
    textAlign: 'left',
    component: (d) => <ShortText>{d}</ShortText>,
  },
  {
    key: 'email',
    label: 'email',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => <ShortText>{d}</ShortText>,
  },
  {
    key: 'name',
    label: 'name',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => <ShortText textTransform="capitalize">{d}</ShortText>,
  },
  {
    key: 'month',
    label: 'month',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => <ShortText>{d}</ShortText>,
  },
  {
    key: 'collectionNumber',
    label: 'collection number',
    ratio: 0.075,
    textAlign: 'left',
    component: (d) => <ShortText>{d}</ShortText>,
  },
  {
    key: 'amount',
    label: 'amount',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => <ShortText textTransform="capitalize">{d}</ShortText>,
  },
  {
    key: 'dueDate',
    label: 'due date',
    ratio: 0.075,
    textAlign: 'left',
    component: (d) => <ShortText textTransform="capitalize">{d}</ShortText>,
  },
  {
    key: 'vaPermata',
    label: 'VA permata',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => <ShortText>{d}</ShortText>,
  },
  {
    key: 'vaBni',
    label: 'VA BNI',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => <ShortText>{d}</ShortText>,
  },
  {
    key: 'vaBri',
    label: 'VA BRI',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => <ShortText>{d}</ShortText>,
  },
  {
    key: 'vaMandiri',
    label: 'VA Mandiri',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => <ShortText>{d}</ShortText>,
  },
  {
    key: 'endingBalance',
    label: 'Ending Balance',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => <ShortText textTransform="capitalize">{d}</ShortText>,
  },
  {
    key: 'additionalInformation',
    label: 'additional information',
    ratio: 0.05,
    textAlign: 'left',
    component: (d) => <ShortText>{d}</ShortText>,
  },
];
