import {
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';

import Layout from '../../components/layout/Layout';
import { Container } from '../../components/general';
import { GenerateCertificate } from '../../components/pages';
import { completionTypeList } from '../../constant';
import { LoadingContext } from '../../contexts/loadingContext';
import { BannerContext } from '../../contexts/bannerContext';
import { isLoading, isNotLoading } from '../../reducers/loadingReducers';
import { isError, isSuccess } from '../../reducers/bannerReducers';
import { courses } from '../../api/get';
import {
  checkEligibility,
  changeCertificateName,
  issueCertificate,
} from '../../api/post';
import { useIsFirstRender } from '../../hooks';

const GenerateCertificatePage = () => {
  const isFirstRender = useIsFirstRender();
  const csvReaderButtonRef = useRef();
  const loadingContext = useContext(LoadingContext);
  const bannerContext = useContext(BannerContext);
  const [courseOptions, setCourseOptions] = useState([]);
  const [formData, setFormData] = useState({
    course: {
      label: '',
      value: '',
    },
    completionType: {
      label: '',
      value: '',
    },
    recipients: [],
    isNotify: false,
  });
  const [checkEligibilityData, setCheckEligibilityData] = useState([]);

  const fetchCourses = async (params) => {
    const coursesRes = await courses(params);
    const options = coursesRes.data.data.map((d) => ({
      label: d.kode_course_title,
      value: d.thinkific_course_id,
    }));

    return options;
  };

  const handleEligibilityCheck = async () => {
    const parsedFormData = {
      course_id: formData.course.value,
      course_name: formData.course.label,
      completion_type: formData.completionType.value,
      recipients: formData.recipients.map(
        ({
          completedAt,
          email,
          firstName,
          lastName,
        }) => ({
          completed_at: completedAt,
          email,
          first_name: firstName,
          last_name: lastName,
        }),
      ),
    };

    try {
      loadingContext.dispatch(isLoading());

      const res = await checkEligibility(parsedFormData);

      setCheckEligibilityData(res.data.data);
    } catch (error) {
      bannerContext.dispatch(isError(error.response.data.message));
    } finally {
      loadingContext.dispatch(isNotLoading());
    }
  };

  const handleFormReset = () => {
    setFormData({
      course: {
        label: '',
        value: '',
      },
      completionType: {
        label: '',
        value: '',
      },
      recipients: [],
      isNotify: false,
    });
    setCheckEligibilityData([]);

    if (csvReaderButtonRef.current) {
      csvReaderButtonRef.current.removeFile();
    }
  };

  const handleIssueCertificate = async () => {
    const eligibleRecipients = checkEligibilityData
      .filter((d) => d.is_eligible)
      .map(({ email }) => email);

    const parsedRecipients = formData.recipients
      .filter(({ email }) => eligibleRecipients.includes(email))
      .map(
        ({
          firstName,
          lastName,
          email,
          preScore,
          postScore,
          completedAt,
        }) => ({
          first_name: firstName,
          last_name: lastName,
          email,
          pre_test_score: parseInt(preScore, 10),
          post_test_score: parseInt(postScore, 10),
          completed_at: completedAt,
        }),
      );

    const parsedRecipientsWithVoucher = formData.recipients
      .filter(({ voucherCode, email }) => !!voucherCode && eligibleRecipients.includes(email))
      .map(({
        firstName,
        lastName,
        email,
        voucherCode,
      }) => ({
        prakerja_name: `${firstName} ${lastName}`,
        email,
        voucher_code: voucherCode,
      }));

    const payload = {
      course_id: formData.course.value,
      course_name: formData.course.label,
      completion_type: formData.completionType.value,
      issued_by: localStorage.getItem('email'),
      is_notify: formData.isNotify,
      recipients: parsedRecipients,
    };

    if (payload.length !== 0) {
      try {
        loadingContext.dispatch(isLoading());

        const promiseArr = [];

        if (formData.completionType.value === 'prakerja') {
          promiseArr.push(changeCertificateName({ data: parsedRecipientsWithVoucher }));
        }

        promiseArr.push(issueCertificate(payload));
        await Promise.all(promiseArr);
        bannerContext.dispatch(
          isSuccess(
            `successfully generate ${parsedRecipients.length} certificates`,
          ),
        );
      } catch (error) {
        bannerContext.dispatch(isError(error.response.data.message));
      } finally {
        handleFormReset();
        loadingContext.dispatch(isNotLoading());
      }
    }
  };

  useEffect(() => {
    const fetchOptions = async () => {
      const params = {
        course_type: formData.completionType.value,
      };

      try {
        loadingContext.dispatch(isLoading());

        const courseOptionsRes = await fetchCourses(params);

        setCourseOptions(courseOptionsRes);
      } catch (error) {
        bannerContext.dispatch(isError(error.response.data.message));
      } finally {
        loadingContext.dispatch(isNotLoading());
      }
    };

    if (!isFirstRender) {
      fetchOptions();
    }
  }, [formData.completionType]);

  return (
    <Layout>
      <Container contentWidthType="FULL">
        <GenerateCertificate
          courseOptions={courseOptions}
          completionTypeList={completionTypeList}
          formData={formData}
          setFormData={setFormData}
          handleEligibilityCheck={handleEligibilityCheck}
          checkEligibilityData={checkEligibilityData}
          handleIssueCertificate={handleIssueCertificate}
          csvReaderButtonRef={csvReaderButtonRef}
          handleFormReset={handleFormReset}
        />
      </Container>
    </Layout>
  );
};

export default GenerateCertificatePage;
