import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const titleStyles = css`
  font-weight: 500;
  text-transform: capitalize;
  font-size: 18px;
  color: ${colors.PRIMARY_BLACK};
`;

export const iframeContainerStyles = css`
  margin: 16px 0 0 0;
`;

export const iframeStyles = css`
  width: 100%;
  min-height: calc(100vh - 260px);
`;
