/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import companyLogo from '../../../assets/svg/logo.svg';
import {
  Button,
  Heading,
  PlainCard,
  Text,
  LoadingModal,
} from '../../general';
import {
  containerStyles,
  companyLogoStyles,
  cardStyles,
  cardTitleStyles,
  buttonStyles,
  errorMessageStyles,
} from './Login.styles';

const Login = ({ handleSignIn, errorData }) => (
  <div css={containerStyles}>
    <img src={companyLogo} css={companyLogoStyles} alt="hacktiv8-logo" />
    <PlainCard styles={cardStyles}>
      <Heading type="h1" styles={cardTitleStyles}>
        Login to OPS
      </Heading>
      <Button
        type="button"
        styles={buttonStyles}
        size="FULL"
        onClick={handleSignIn}
        inverted
      >
        Login with Google
      </Button>
      {errorData.status && <Text styles={errorMessageStyles}>{errorData.message}</Text>}
    </PlainCard>
    <LoadingModal />
  </div>
);

Login.propTypes = {
  handleSignIn: PropTypes.func.isRequired,
  errorData: PropTypes.shape({
    status: PropTypes.bool,
    message: PropTypes.string,
  }).isRequired,
};

export default Login;
