import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const primaryCardStyles = css`
  width: 50%;
  padding: 24px;
  border-radius: 16px;
  background-color: ${colors.WHITE};
`;
