/** @jsxImportSource @emotion/react */
import { Container } from '../../general';
import {
  // titleStyles,
  iframeContainerStyles,
  iframeStyles,
} from './Dashboard.styles';

const Dashboard = () => (
  <Container contentWidthType="FULL">
    {/* <ShortText styles={titleStyles}>metabase</ShortText> */}
    <div css={iframeContainerStyles}>
      <iframe
        src="https://base.hacktiv8.com/public/question/281fb2de-29aa-478f-a025-07c7c0420bbc"
        css={iframeStyles}
        title="example"
        frameBorder="0"
        width="800"
        height="900"
        allowtransparency="true"
      />
    </div>
  </Container>
);

export default Dashboard;
