/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Fragment } from 'react';
import { CSVLink } from 'react-csv';

import {
  Container,
  PlainCard,
  Input,
  ShortText,
  Button,
} from '../../general';
import { vendorList, voucherTypeList } from '../../../constant';
import {
  contentStyles,
  rowStyles,
  labelStyles,
  reactSelectStyles,
  submitButtonContainerStyles,
  submitButtonStyles,
} from './CreateVoucher.styles';

const CreateVoucher = ({
  courseOptions,
  bundleOptions,
  formData,
  setFormData,
  successData,
  voucherData,
  handleSubmit,
  handleResetForm,
}) => {
  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleVendorChange = (d) => {
    setFormData((prevState) => ({
      ...prevState,
      vendor: d,
    }));
  };

  const handleVoucherTypeChange = (d) => {
    setFormData((prevState) => ({
      ...prevState,
      voucherType: d,
    }));
  };

  const handleCourseChange = (d) => {
    setFormData((prevState) => ({
      ...prevState,
      course: d,
    }));
  };

  const handleBundleChange = (d) => {
    setFormData((prevState) => ({
      ...prevState,
      bundle: d,
    }));
  };

  const disableButtonStatusChecker = () => {
    if (formData.totalVoucher
      && formData.vendor.value
      && formData.voucherType.value
      && (formData.course.value
      || formData.bundle.value)
    ) {
      return false;
    }

    return true;
  };

  return (
    <Container contentWidthType="FULL" contentStyles={contentStyles}>
      <PlainCard>
        <Input
          type="number"
          label="total voucher"
          min="1"
          max="9999"
          name="totalVoucher"
          value={formData.totalVoucher}
          onChange={handleChange}
          placeholder="500"
        />
        <div css={rowStyles}>
          <ShortText styles={labelStyles}>select vendor</ShortText>
          <Select
            id="vendor"
            instanceId="vendor"
            css={reactSelectStyles}
            classNamePrefix="select"
            name="vendor"
            value={formData.vendor}
            options={vendorList}
            onChange={handleVendorChange}
            isSearchable
            placeholder="vendor"
          />
        </div>
        <div css={rowStyles}>
          <ShortText styles={labelStyles}>select voucher type</ShortText>
          <Select
            id="course"
            instanceId="course"
            css={reactSelectStyles}
            classNamePrefix="select"
            name="course"
            value={formData.voucherType}
            options={voucherTypeList}
            onChange={handleVoucherTypeChange}
            isSearchable
            placeholder="course"
          />
        </div>
        {formData.voucherType.value !== 'kode_bundle' && (
          <div css={rowStyles}>
            <ShortText styles={labelStyles}>select course</ShortText>
            <Select
              id="course"
              instanceId="course"
              css={reactSelectStyles}
              classNamePrefix="select"
              name="course"
              value={formData.course}
              options={courseOptions}
              onChange={handleCourseChange}
              isSearchable
              placeholder="course"
            />
          </div>
        )}
        {formData.voucherType.value === 'kode_bundle' && (
          <div css={rowStyles}>
            <ShortText styles={labelStyles}>select bundle</ShortText>
            <Select
              id="bundle"
              instanceId="bundle"
              css={reactSelectStyles}
              classNamePrefix="select"
              name="bundle"
              value={formData.bundle}
              options={bundleOptions}
              onChange={handleBundleChange}
              isSearchable
              placeholder="bundle"
            />
          </div>
        )}
        <Input
          type="date"
          containerStyles={rowStyles}
          label="expiry date"
          name="expiryDate"
          value={formData.expiryDate}
          onChange={handleChange}
          placeholder="choose date"
        />
        <div css={submitButtonContainerStyles}>
          {successData.status && (
            <Fragment>
              <CSVLink
                filename={`${successData.courseName}_${successData.vendor}_${successData.currentDate}`}
                data={voucherData}
              >
                <Button
                  type="button"
                  size="L"
                  inverted
                >
                  {`download ${successData.vendor} CSV`}
                </Button>
              </CSVLink>
              <Button
                type="submit"
                styles={submitButtonStyles}
                size="S"
                color="DANGER_RED"
                onClick={handleResetForm}
                inverted
              >
                reset
              </Button>
            </Fragment>
          )}
          <Button
            type="submit"
            styles={submitButtonStyles}
            size="M"
            color="LIGHT_BLUE"
            onClick={handleSubmit}
            disabled={disableButtonStatusChecker()}
          >
            create
          </Button>
        </div>
      </PlainCard>
    </Container>
  );
};

CreateVoucher.propTypes = {
  formData: PropTypes.shape({
    totalVoucher: PropTypes.string,
    vendor: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    voucherType: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    course: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    bundle: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    expiryDate: PropTypes.string,
  }).isRequired,
  courseOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  bundleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  setFormData: PropTypes.func.isRequired,
  successData: PropTypes.shape({
    status: PropTypes.bool,
    courseName: PropTypes.string,
    vendor: PropTypes.string,
    currentDate: PropTypes.string,
  }).isRequired,
  voucherData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
    .isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleResetForm: PropTypes.func.isRequired,
};

export default CreateVoucher;
