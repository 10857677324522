import { userService } from '../services';

const logout = () => {
  const credentials = JSON.parse(localStorage.getItem('credentials'));

  return userService.post(
    '/auth/logout',
    null,
    {
      headers: {
        Authorization: `Bearer ${credentials.access_token}`,
      },
    },
  );
};

export default logout;
