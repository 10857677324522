export default [
  {
    label: 'Full Time Program',
    value: 'full_time',
  },
  {
    label: 'Part Time Program',
    value: 'part_time',
  },
  {
    label: 'Prakerja',
    value: 'prakerja',
  },
  {
    label: 'Trial Prakerja',
    value: 'trial_prakerja',
  },
  {
    label: 'Kode single course',
    value: 'kode_course',
  },
  {
    label: 'Kode bundling course',
    value: 'kode_bundle',
  },
  {
    label: 'Spark AR',
    value: 'spark',
  },
  {
    label: 'Kampus Merdeka',
    value: 'kampus_merdeka',
  },
  {
    label: 'Study Independent',
    value: 'study_independent',
  },
  {
    label: 'Kidz',
    value: 'kidz',
  },
];
