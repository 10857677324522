/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import ShortText from '../ShortText/ShortText';

import {
  tableContainerStyles,
  tableOverflowStyles,
  tableStyles,
  tableHeaderContainerStyles,
  tableHeaderStyles,
  tableBodyContainerStyles,
  tableDataContainerStyles,
  tableDataStyles,
} from './Table.styles';

const Table = ({ styles, tableConfig, data }) => (
  <div css={[tableContainerStyles, styles]}>
    <div css={tableOverflowStyles}>
      <div css={tableStyles}>
        <div css={tableHeaderContainerStyles}>
          {tableConfig.map(({
            key,
            label,
            sort,
            ratio,
            textAlign,
          }) => (
            <div
              key={`${key}-${label}`}
              css={tableHeaderStyles(sort, ratio)}
              className={textAlign}
            >
              <ShortText fontSize={16} textTransform="capitalize">
                {label}
              </ShortText>
            </div>
          ))}
        </div>
        <div css={tableBodyContainerStyles} className="tableBody">
          {data.map((d) => (
            <div key={d.id} css={tableDataContainerStyles}>
              {tableConfig.map(({
                key,
                label,
                ratio,
                textAlign,
                component,
              }) => (
                <div
                  key={`${key}-${label}`}
                  css={tableDataStyles(ratio)}
                  className={textAlign}
                >
                  {component(d[key])}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

Table.propTypes = {
  styles: PropTypes.shape({}),
  tableConfig: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
      sort: PropTypes.bool,
      ratio: PropTypes.number,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Table.defaultProps = {
  styles: css``,
};

export default Table;
