import { ShortText } from '../../general';

export default [
  {
    key: 'no',
    label: 'No',
    ratio: 0.075,
    textAlign: 'left',
    component: (d) => <ShortText>{d}</ShortText>,
  },
  {
    key: 'redemptionCode',
    label: 'Redemption Code',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => <ShortText>{d}</ShortText>,
  },
  {
    key: 'scope',
    label: 'Scope',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => <ShortText textTransform="lowercase">{d}</ShortText>,
  },
  {
    key: 'sequence',
    label: 'Sequence',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => <ShortText>{d}</ShortText>,
  },
  {
    key: 'notes',
    label: 'Notes',
    ratio: 0.075,
    textAlign: 'left',
    component: (d) => <ShortText>{d}</ShortText>,
  },
];
