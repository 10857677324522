import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const contentStyles = css`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const rowStyles = css`
  margin: 16px 0 0 0;
`;

export const labelStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.BLACK};
`;

export const reactSelectStyles = css`
  width: 100%;
  margin: 8px 0 0 0;

  .select__control {
    padding: 2px 0;
    border: 1px solid ${colors.GREY};
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      border-color: ${colors.LIGHT_BLUE};
    }

    .select__indicator-separator {
      background-color: ${colors.GREY};
    }

    .select__indicator {
      color: ${colors.LIGHT_BLUE};
    }

    .select__value-container {
      .select__placeholder {
        padding: 0 10px;
        text-transform: capitalize;
        font-size: 14px;
        color: ${colors.GREY};
      }
    }
  }
`;

export const submitButtonContainerStyles = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 64px 0 0 0;
`;

export const submitButtonStyles = css`
  margin: 0 0 0 16px;
`;
