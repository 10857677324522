const defaultState = {
  isError: false,
  message: '',
};

export const reducer = (_, action) => {
  switch (action.type) {
    case 'ERROR':
      return {
        isError: true,
        message: action.message,
      };
    case 'SUCCESS':
      return {
        isError: false,
        message: action.message,
      };
    default:
      return defaultState;
  }
};

export const isError = (message) => ({
  type: 'ERROR',
  message,
});

export const isSuccess = (message) => ({
  type: 'SUCCESS',
  message,
});
