import { css } from '@emotion/react';

import { colors, contentWidth } from '../../../configs';

export const footerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: ${colors.PRIMARY_BLACK};
`;

export const footerContentStyles = css`
  width: ${contentWidth.FULL}px;
`;

export const descriptionStyles = css`
  text-transform: capitalize;
  font-size: 12px;
  color: ${colors.WHITE};
`;
