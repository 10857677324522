/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
import { CSVReader } from 'react-papaparse';

import * as css from './ISA.styles';
import previewTableConfig from './previewTable.config';
import {
  Button,
  Container,
  PlainCard,
  ShortText,
  Table,
} from '../../general';

const ISA = ({ formData, setFormData, handleSubmit }) => {
  const csvReaderButtonRef = useRef();
  const [isCSVReset, setCSVResetStatus] = useState(true);

  const handleOnDrop = (data) => {
    const parsedCSV = data
      .map((d, idx) => ({
        no: idx,
        to: d.data[0],
        email: d.data[0],
        name: d.data[1],
        month: d.data[2],
        collectionNumber: d.data[3],
        amount: d.data[4],
        dueDate: d.data[5],
        vaPermata: d.data[6],
        vaBni: d.data[7],
        vaBri: d.data[8],
        vaMandiri: d.data[9],
        endingBalance: d.data[10],
        additionalInformation: d.data[11],
      }))
      .slice(1);

    setFormData(parsedCSV);
  };

  const handleReset = () => {
    setCSVResetStatus((prevState) => !prevState);
    setFormData([]);
  };

  return (
    <Container contentWidthType="FULL" contentStyles={css.contentStyles}>
      <PlainCard styles={css.cardStyles}>
        {/* <div css={css.rowStyles}>
          <ShortText styles={css.labelStyles}>select date</ShortText>
          <DatePicker
            locale="id"
            selected={date}
            dateFormat="dd MMMM yyyy"
            onChange={handleDateChange}
          />
        </div> */}
        <div css={css.rowStyles}>
          <ShortText styles={css.csvLabelStyles}>select CSV file</ShortText>
          <CSVReader
            isReset={isCSVReset}
            ref={csvReaderButtonRef}
            onDrop={handleOnDrop}
            addRemoveButton
            removeButtonColor="#659cef"
          >
            <ShortText>Drop CSV file here or click to upload.</ShortText>
          </CSVReader>
        </div>
        {formData.length !== 0 && (
          <>
            <Table
              styles={css.tableStyles}
              tableConfig={previewTableConfig}
              data={formData}
            />
            <div css={css.submitButtonContainerStyles}>
              <Button
                type="button"
                styles={css.submitButtonStyles}
                size="S"
                color="DANGER_RED"
                onClick={handleReset}
                inverted
              >
                reset
              </Button>
              <Button
                type="button"
                styles={css.submitButtonStyles}
                size="L"
                color="LIGHT_BLUE"
                onClick={handleSubmit}
              >
                upload collection
              </Button>
            </div>
          </>
        )}
      </PlainCard>
    </Container>
  );
};

ISA.propTypes = {
  formData: PropTypes.arrayOf(
    PropTypes.shape({
      templateName: PropTypes.string,
      to: PropTypes.string,
      params: PropTypes.shape({
        name: PropTypes.string,
        month: PropTypes.string,
        collectionNumber: PropTypes.Number,
        amount: PropTypes.Number,
        vaPermata: PropTypes.string,
        vaBNI: PropTypes.string,
        vaBRI: PropTypes.string,
        vaMandiri: PropTypes.string,
        dueDate: PropTypes.string,
      }),
    }),
  ).isRequired,
  setFormData: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ISA;
