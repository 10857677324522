import { ShortText } from '../../general';
import { completionTypeList } from '../../../constant';

export default [
  {
    key: 'id',
    label: 'id',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => <ShortText>{d}</ShortText>,
  },
  {
    key: 'kode_course_title',
    label: 'course title',
    ratio: 0.3,
    textAlign: 'left',
    component: (d) => (
      <ShortText textTransform="capitalize">{d || '-'}</ShortText>
    ),
  },
  {
    key: 'thinkific_course_id',
    label: 'thinkific course id',
    ratio: 0.15,
    textAlign: 'left',
    component: (d) => (
      <ShortText textTransform="capitalize">{d || '-'}</ShortText>
    ),
  },
  {
    key: 'thinkific_product_id',
    label: 'thinkific product id',
    ratio: 0.15,
    textAlign: 'left',
    component: (d) => (
      <ShortText textTransform="capitalize">{d || '-'}</ShortText>
    ),
  },
  {
    key: 'course_type',
    label: 'course type',
    ratio: 0.15,
    textAlign: 'left',
    component: (d) => {
      const parsedCourseType = completionTypeList.find(
        ({ value }) => value === d,
      );

      return (
        <ShortText textTransform="capitalize">
          {parsedCourseType ? parsedCourseType.label : '-'}
        </ShortText>
      );
    },
  },
  {
    key: 'action',
    label: 'action',
    ratio: 0.15,
    textAlign: 'left',
    component: (d) => d,
  },
];
