import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';

import { reducer } from '../reducers/bannerReducers';

export const BannerContext = createContext();

export const BannerProvider = ({ children }) => {
  const [value, dispatch] = useReducer(reducer, false);

  return (
    <BannerContext.Provider
      value={{
        value,
        dispatch,
      }}
    >
      {children}
    </BannerContext.Provider>
  );
};

BannerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
