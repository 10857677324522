import PropTypes from 'prop-types';
import { useLocation, Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({ component: Component }, ...rest) => {
  const location = useLocation();
  const credentials = JSON.parse(localStorage.getItem('credentials'));

  return credentials ? (
    <Route render={(props) => <Component {...props} />} {...rest} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default ProtectedRoute;
