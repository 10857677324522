import { css } from '@emotion/react';
import 'react-datepicker/dist/react-datepicker.css';

import { colors } from '../../../configs';

export const contentStyles = css`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const cardStyles = css`
  width: 100%;
`;

export const rowStyles = css`
  margin: 16px 0 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

export const labelStyles = css`
  display: block;
  margin: 0 0 8px 0;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.BLACK};
`;

export const csvLabelStyles = css`
  display: block;
  margin: 0 0 8px 0;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.BLACK};
`;

export const tableStyles = css`
  margin: 32px 0 0 0;
`;

export const submitButtonContainerStyles = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const submitButtonStyles = css`
  margin: 32px 0 0 16px;
`;
