/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Fragment } from 'react';
import { CSVReader } from 'react-papaparse';

import previewTableConfig from './previewTable.config';
import {
  Container,
  PlainCard,
  ShortText,
  Button,
  Table,
  Checkbox,
} from '../../general';
import { completionTypeList } from '../../../constant';
import {
  contentStyles,
  cardStyles,
  rowStyles,
  labelStyles,
  csvLabelStyles,
  reactSelectStyles,
  submitButtonContainerStyles,
  submitButtonStyles,
  tableStyles,
  issueCertificateButtonContainerStyles,
} from './GenerateCertificate.styles';

const GenerateCertificate = ({
  courseOptions,
  formData,
  setFormData,
  handleEligibilityCheck,
  checkEligibilityData,
  handleIssueCertificate,
  csvReaderButtonRef,
  handleFormReset,
}) => {
  const handleCourseChange = (d) => {
    setFormData((prevState) => ({
      ...prevState,
      course: d,
    }));
  };

  const handleCompletionTypeChange = (d) => {
    setFormData((prevState) => ({
      ...prevState,
      completionType: d,
    }));
  };

  const handleOnDrop = (data) => {
    const parsedData = data
      .map((d) => ({
        completedAt: d.data[0],
        email: d.data[1],
        firstName: d.data[2],
        lastName: d.data[3],
        preScore: d.data[4],
        postScore: d.data[5],
        voucherCode: d.data[6],
      }))
      .slice(1);

    setFormData((prevState) => ({
      ...prevState,
      recipients: parsedData,
    }));
  };

  const handleCheckboxChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.checked,
    }));
  };

  const disableButtonStatusChecker = () => {
    if (formData.course.value
      && formData.completionType.value
      && formData.recipients.length !== 0
    ) {
      return false;
    }

    return true;
  };

  return (
    <Container contentWidthType="FULL" contentStyles={contentStyles}>
      <PlainCard styles={cardStyles}>
        <div css={rowStyles}>
          <ShortText styles={labelStyles}>select completion type</ShortText>
          <Select
            id="completionType"
            instanceId="completionType"
            css={reactSelectStyles}
            classNamePrefix="select"
            name="completionType"
            value={formData.completionType}
            options={completionTypeList}
            onChange={handleCompletionTypeChange}
            isSearchable
            placeholder="completion type"
          />
        </div>
        <div css={rowStyles}>
          <ShortText styles={labelStyles}>select course</ShortText>
          <Select
            id="course"
            instanceId="course"
            css={reactSelectStyles}
            classNamePrefix="select"
            name="course"
            value={formData.course}
            options={courseOptions}
            onChange={handleCourseChange}
            isSearchable
            placeholder="course"
          />
        </div>
        <div css={rowStyles}>
          <ShortText styles={csvLabelStyles}>select CSV file</ShortText>
          <CSVReader
            ref={csvReaderButtonRef}
            onDrop={handleOnDrop}
            addRemoveButton
            removeButtonColor="#659cef"
          >
            <ShortText>Drop CSV file here or click to upload.</ShortText>
          </CSVReader>
        </div>
        <div css={submitButtonContainerStyles}>
          <Button
            type="button"
            size="S"
            color="DANGER_RED"
            onClick={handleFormReset}
            inverted
          >
            reset
          </Button>
          <Button
            type="button"
            styles={submitButtonStyles}
            size="L"
            color="LIGHT_BLUE"
            onClick={handleEligibilityCheck}
            disabled={disableButtonStatusChecker()}
          >
            check eligibility
          </Button>
        </div>
        {checkEligibilityData.length !== 0 && (
          <Fragment>
            <Table
              styles={tableStyles}
              tableConfig={previewTableConfig}
              data={checkEligibilityData}
            />
            <div css={issueCertificateButtonContainerStyles}>
              <Checkbox
                checkboxLabel="notify recipients"
                name="isNotify"
                value={formData.isNotify}
                onChange={handleCheckboxChange}
              />
              <Button
                type="button"
                styles={submitButtonStyles}
                size="L"
                color="LIGHT_BLUE"
                onClick={handleIssueCertificate}
                disabled={disableButtonStatusChecker()}
              >
                issue certificate
              </Button>
            </div>
          </Fragment>
        )}
      </PlainCard>
    </Container>
  );
};

GenerateCertificate.propTypes = {
  courseOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  formData: PropTypes.shape({
    course: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    completionType: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    recipients: PropTypes.arrayOf(
      PropTypes.shape({
        completedAt: PropTypes.string,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    ).isRequired,
    isNotify: PropTypes.bool,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  handleEligibilityCheck: PropTypes.func.isRequired,
  checkEligibilityData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleIssueCertificate: PropTypes.func.isRequired,
  csvReaderButtonRef: PropTypes.shape({}).isRequired,
  handleFormReset: PropTypes.func.isRequired,
};

export default GenerateCertificate;
