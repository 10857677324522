/** @jsxImportSource @emotion/react */
import { useContext } from 'react';

import Modal from '../Modal/Modal';
import Icon from '../Icon/Icon';
import ShortText from '../ShortText/ShortText';
import Text from '../Text/Text';
import PlainCard from '../PlainCard/PlainCard';
import { LoadingContext } from '../../../contexts/loadingContext';
import {
  cardStyles,
  loaderIconContainerStyles,
  loaderIconStyles,
  titleStyles,
  descriptionStyles,
} from './LoadingModal.styles';

const LoadingModal = () => {
  const { isLoading } = useContext(LoadingContext);

  return (
    <Modal isModalShown={isLoading}>
      <PlainCard styles={cardStyles}>
        <div css={loaderIconContainerStyles}>
          <Icon name="loader-5-line" styles={loaderIconStyles} />
        </div>
        <ShortText styles={titleStyles}>loading</ShortText>
        <Text styles={descriptionStyles}>
          please wait a moment
        </Text>
      </PlainCard>
    </Modal>
  );
};

export default LoadingModal;
