import { css } from '@emotion/react';

import { colors, contentWidth } from '../../configs';

export const bannerStyles = (isError) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 8px 0;
  background-color: ${isError ? colors.DANGER_RED : colors.SUCCESS_GREEN};
`;

export const messageStyles = css`
  max-width: ${contentWidth.FULL}px;
  text-align: center;
  font-size: 14px;
  color: ${colors.WHITE};
`;

export const bodyStyles = (isPaddingActive) => css`
  width: 100%;
  min-height: calc(100vh - 140px);
  padding: ${isPaddingActive ? '36px 0' : 0};
`;
