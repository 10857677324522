export default {
  TRANSPARENT: 'transparent',
  PRIMARY_BLUE: '#1d3d71',
  PRIMARY_YELLOWISH_ORANGE: '#f5942e',
  PRIMARY_ORANGE: '#f16634',
  PRIMARY_BLACK: '#231f20',
  WHITE: '#fff',
  GREY: '#d7d7d7',
  BACKGROUND_GREY: '#e5e5e5',
  BABY_BLUE: '#eff3fa',
  LIGHT_BLUE: '#1e5bbd',
  LIGHT_GREY: '#f9f9f9',
  DARK_GREY: '#a0a0a0',
  DARKER_GREY: '#999999',
  DANGER_RED: '#f16634',
  SUCCESS_GREEN: '#39db80',
};
