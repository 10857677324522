import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import routeList from './routes/routeList';
import ProtectedRoute from './routes/ProtectedRoute';
import { ScrollToTop } from './helpers/general';

const App = () => (
  <Router>
    <ScrollToTop />
    <Switch>
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
      {routeList.map(({ path, component: Component, isPrivate }) => (
        isPrivate ? (
          <ProtectedRoute key={path} path={path} component={Component} exact />
        ) : (
          <Route key={path} path={path} exact>
            <Component />
          </Route>
        )))}
    </Switch>
  </Router>
);

export default App;
