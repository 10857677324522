import { clawnFoxService } from '../services';

const ISAPaymentCollection = (data) => {
  const credentials = JSON.parse(localStorage.getItem('credentials'));

  return clawnFoxService.post('/emails/isa-repayment', data, {
    headers: {
      Authorization: `Bearer ${credentials.access_token}`,
    },
  });
};

export default ISAPaymentCollection;
