import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import { ISA } from '../../components/pages';
import { LoadingContext } from '../../contexts/loadingContext';
import { BannerContext } from '../../contexts/bannerContext';
import { isLoading, isNotLoading } from '../../reducers/loadingReducers';
import { isError, isSuccess } from '../../reducers/bannerReducers';
import { ISAPaymentCollection } from '../../api/post';

const ISAPage = () => {
  const history = useHistory();
  const loadingContext = useContext(LoadingContext);
  const bannerContext = useContext(BannerContext);
  const [formData, setFormData] = useState([]);

  const handleSubmit = async () => {
    try {
      loadingContext.dispatch(isLoading());

      await ISAPaymentCollection(formData);
      bannerContext.dispatch(
        isSuccess('successfully uploaded payment collection.'),
      );
    } catch (error) {
      bannerContext.dispatch(isError(error.response.data.message));
      history.push('/login');
    } finally {
      loadingContext.dispatch(isNotLoading());
    }
  };

  return (
    <Layout isPaddingActive={false}>
      <ISA
        formData={formData}
        setFormData={setFormData}
        handleSubmit={handleSubmit}
      />
    </Layout>
  );
};

export default ISAPage;
