import { css } from '@emotion/react';

import { colors, contentWidth } from '../../../configs';

export const navbarStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: ${colors.PRIMARY_BLUE};
`;

export const navbarContentStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${contentWidth.FULL}px;
  height: 100%;
`;

export const menuListContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

export const listStyles = css`
  position: relative;
  height: 100%;
  margin: 0 32px 0 0;

  &:last-of-type {
    margin: 0;
  }
`;

export const linkStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 16px;

  &.isMenuActive {
    &:after {
      background-color: ${colors.PRIMARY_YELLOWISH_ORANGE};
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: ${colors.TRANSPARENT};
  }
`;
