/** @jsxImportSource @emotion/react */
import { Text } from '../../general';
import {
  footerStyles,
  footerContentStyles,
  descriptionStyles,
} from './Footer.styles';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div css={footerStyles}>
      <div css={footerContentStyles}>
        <Text styles={descriptionStyles}>
          &copy;&nbsp;
          {currentYear}
          &nbsp;PT hacktivate teknologi indonesia. all rights reserved.
        </Text>
      </div>
    </div>
  );
};

export default Footer;
