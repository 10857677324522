import { ShortText } from '../../general';

export default [
  {
    key: 'email',
    label: 'email',
    ratio: 0.25,
    textAlign: 'left',
    component: (d) => <ShortText>{d}</ShortText>,
  },
  {
    key: 'first_name',
    label: 'first name',
    ratio: 0.125,
    textAlign: 'left',
    component: (d) => <ShortText textTransform="capitalize">{d}</ShortText>,
  },
  {
    key: 'last_name',
    label: 'last name',
    ratio: 0.125,
    textAlign: 'left',
    component: (d) => <ShortText textTransform="capitalize">{d}</ShortText>,
  },
  {
    key: 'completed_at',
    label: 'completed at',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => <ShortText textTransform="capitalize">{d}</ShortText>,
  },
  {
    key: 'is_eligible',
    label: 'is eligible',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => <ShortText textTransform="capitalize">{d ? 'yes' : 'no'}</ShortText>,
  },
  {
    key: 'reasons',
    label: 'reasons',
    ratio: 0.3,
    textAlign: 'left',
    component: (d) => <ShortText textTransform="capitalize">{d}</ShortText>,
  },
];
