import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const containerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: ${colors.PRIMARY_BLUE};
`;

export const companyLogoStyles = css`
  width: 200px;
  height: auto;
`;

export const cardStyles = css`
  width: 300px;
  height: 250px;
  margin: 32px 0 0 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`;

export const cardTitleStyles = css`
  padding: 0 0 8px 0;
  text-align: center;
  text-transform: none;
  font-weight: 500;
  font-size: 28px;
  border-bottom: 1px solid ${colors.GREY};
`;

export const buttonStyles = css`
  margin: 16px 0 0 0;
  text-transform: none;
`;

export const errorMessageStyles = css`
  margin: 16px 0 0 0;
  text-align: center;
  color: ${colors.DANGER_RED};
`;
