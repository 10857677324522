/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { CSVReader } from 'react-papaparse';

import * as css from './PrakerjaFeedback.styles';
import previewTableConfig from './previewTable.config';
import {
  Button,
  Container,
  PlainCard,
  ShortText,
  Table,
} from '../../general';

const PrakerjaFeedback = ({
  formData, setFormData, isCSVReset, handleReset, handleSubmit,
}) => {
  const csvReaderButtonRef = useRef();

  const handleOnDrop = (data) => {
    const parsedCSV = data
      .map((d, idx) => ({
        no: idx,
        redemptionCode: d.data[0],
        scope: d.data[1],
        sequence: d.data[2],
        notes: d.data[3],
      }))
      .slice(1);

    setFormData(parsedCSV);
  };

  return (
    <Container contentWidthType="FULL" contentStyles={css.contentStyles}>
      <PlainCard styles={css.cardStyles}>
        {/* <div css={css.rowStyles}>
          <ShortText styles={css.labelStyles}>select date</ShortText>
          <DatePicker
            locale="id"
            selected={date}
            dateFormat="dd MMMM yyyy"
            onChange={handleDateChange}
          />
        </div> */}
        <div css={css.rowStyles}>
          <ShortText styles={css.csvLabelStyles}>select CSV file</ShortText>
          <CSVReader
            isReset={isCSVReset}
            ref={csvReaderButtonRef}
            onDrop={handleOnDrop}
            addRemoveButton
            removeButtonColor="#659cef"
          >
            <ShortText>Drop CSV file here or click to upload.</ShortText>
          </CSVReader>
        </div>
        {formData.length !== 0 && (
          <>
            <Table
              styles={css.tableStyles}
              tableConfig={previewTableConfig}
              data={formData}
            />
            <div css={css.submitButtonContainerStyles}>
              <Button
                type="button"
                styles={css.submitButtonStyles}
                size="S"
                color="DANGER_RED"
                onClick={handleReset}
                inverted
              >
                reset
              </Button>
              <Button
                type="button"
                styles={css.submitButtonStyles}
                size="L"
                color="LIGHT_BLUE"
                onClick={handleSubmit}
              >
                submit feedback
              </Button>
            </div>
          </>
        )}
      </PlainCard>
    </Container>
  );
};

PrakerjaFeedback.propTypes = {
  formData: PropTypes.arrayOf(
    PropTypes.shape({
      redemptionCode: PropTypes.string,
      scope: PropTypes.string,
      sequence: PropTypes.string,
      notes: PropTypes.string,
    }),
  ).isRequired,
  setFormData: PropTypes.func.isRequired,
  isCSVReset: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default PrakerjaFeedback;
