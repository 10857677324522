import { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Login } from '../../components/pages';
import { firebase, googleProvider } from '../../configs';
import { introspection, loginWithGoogle } from '../../api/post';
import { LoadingContext } from '../../contexts/loadingContext';
import { isLoading, isNotLoading } from '../../reducers/loadingReducers';

const LoginPage = () => {
  const history = useHistory();
  const { dispatch } = useContext(LoadingContext);
  const [errorData, setErrorData] = useState({ status: false, message: '' });

  const handleSignIn = async () => {
    setErrorData({
      status: false,
      message: '',
    });

    try {
      dispatch(isLoading());
      await firebase.auth().signInWithPopup(googleProvider);
      const isEmailAllowed = await firebase
        .auth()
        .currentUser?.email?.split('@')[1]
        .includes('hacktiv8');

      if (isEmailAllowed) {
        const email = await firebase.auth().currentUser.email;
        const idToken = await firebase.auth().currentUser.getIdToken(true);
        const refreshToken = await firebase.auth().currentUser.refreshToken;
        const payload = {
          grant_type: 'mfa_google',
          token_id: idToken,
          refresh_token: refreshToken,
        };
        const loginWithGoogleRes = await loginWithGoogle(payload);

        localStorage.setItem('email', email);
        localStorage.setItem(
          'credentials',
          JSON.stringify(loginWithGoogleRes.data.data),
        );
        history.push('/dashboard');
      } else {
        setErrorData({
          status: true,
          message: 'user is not part of hacktiv8',
        });
      }
    } catch (error) {
      if (error.response.data.message === 'No associated email related to user yet') {
        setErrorData({
          status: true,
          message: 'user does not exist',
        });
      }
    } finally {
      dispatch(isNotLoading());
    }
  };

  useEffect(() => {
    const credentials = JSON.parse(localStorage.getItem('credentials'));

    const fetchIntrospection = async () => {
      try {
        const res = await introspection();

        if (res.data.code === 200) history.push('/dashboard');
      } catch (error) {
        setErrorData({
          status: true,
          message: error.data.response.message,
        });
      }
    };

    if (credentials) fetchIntrospection();
  }, []);

  return <Login handleSignIn={handleSignIn} errorData={errorData} />;
};

export default LoginPage;
