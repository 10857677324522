import axios from 'axios';

const userService = axios.create({
  baseURL: `${process.env.REACT_APP_USER_API_ENDPOINT}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const kodeService = axios.create({
  baseURL: process.env.REACT_APP_KODE_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Key': process.env.REACT_APP_KODE_API_KEY,
  },
});

const certificateService = axios.create({
  baseURL: process.env.REACT_APP_CERTIFICATE_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Key': process.env.REACT_APP_CERTIFICATE_API_KEY,
  },
});

const notificationService = axios.create({
  baseURL: process.env.REACT_APP_NOTIFICATION_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

const clawnFoxService = axios.create({
  baseURL: process.env.REACT_APP_CLAWNFOX_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

export {
  clawnFoxService,
  userService,
  kodeService,
  certificateService,
  notificationService,
};
