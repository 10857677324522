import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { LoadingProvider } from './contexts/loadingContext';
import { BannerProvider } from './contexts/bannerContext';
import './css/index.css';

ReactDOM.render(
  <React.StrictMode>
    <LoadingProvider>
      <BannerProvider>
        <App />
      </BannerProvider>
    </LoadingProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
