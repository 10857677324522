import { useState, useContext } from 'react';

import Layout from '../../components/layout/Layout';
import { PrakerjaFeedback } from '../../components/pages';
import { LoadingContext } from '../../contexts/loadingContext';
import { BannerContext } from '../../contexts/bannerContext';
import { isLoading, isNotLoading } from '../../reducers/loadingReducers';
import { isError, isSuccess } from '../../reducers/bannerReducers';
import { submitPrakerjaFeedback } from '../../api/post';
// import { submitPrakerjaFeedback } from '../../api/post';

const PrakerjaFeedbackPage = () => {
  const loadingContext = useContext(LoadingContext);
  const bannerContext = useContext(BannerContext);
  const [formData, setFormData] = useState([]);
  const [isCSVReset, setCSVResetStatus] = useState(true);

  const handleReset = () => {
    setCSVResetStatus((prevState) => !prevState);
    setFormData([]);
  };

  const handleSubmit = async () => {
    try {
      loadingContext.dispatch(isLoading());

      await Promise.all(formData.map((d) => submitPrakerjaFeedback({
        redemption_code: d.redemptionCode,
        scope: d.scope.toLowerCase(),
        sequence: Number(d.sequence),
        notes: d.notes,
      })));
      // await submitPrakerjaFeedback(formData);
      bannerContext.dispatch(
        isSuccess('successfully uploaded submission.'),
      );
      handleReset();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      bannerContext.dispatch(isError(error.response.data.message));
      // history.push('/login');
    } finally {
      loadingContext.dispatch(isNotLoading());
    }
  };

  return (
    <Layout isPaddingActive={false}>
      <PrakerjaFeedback
        formData={formData}
        setFormData={setFormData}
        isCSVReset={isCSVReset}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
      />
    </Layout>
  );
};

export default PrakerjaFeedbackPage;
