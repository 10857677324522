/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useState } from 'react';

import tableConfig from './table.config';
import {
  Button,
  Container,
  InputWithIcon,
  PlainCard,
  ShortText,
  Table,
} from '../../general';
import { completionTypeList } from '../../../constant';
import {
  contentStyles,
  buttonAndInputContainerStyles,
  inputStyles,
  tableStyles,
  cardStyles,
  rowStyles,
  labelStyles,
  courseTitleStyles,
  reactSelectStyles,
  submitButtonContainerStyles,
} from './Tags.styles';

const Tags = ({
  handleSyncCourses,
  selectedRow,
  setSelectedRow,
  courseData,
  handleSubmit,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTagChange = (d) => {
    setSelectedRow((prevState) => ({
      ...prevState,
      courseType: d,
    }));
  };

  return (
    <Container contentWidthType="FULL" contentStyles={contentStyles}>
      <div css={buttonAndInputContainerStyles}>
        <Button
          type="button"
          size="M"
          color="LIGHT_BLUE"
          onClick={handleSyncCourses}
        >
          sync courses
        </Button>
        <InputWithIcon
          type="text"
          containerStyles={inputStyles}
          name="searchTerm"
          iconName="search-line"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="search course name"
        />
      </div>
      <Table
        styles={tableStyles}
        tableConfig={tableConfig}
        data={courseData.filter((d) => d.kode_course_title
          .includes(searchTerm.toLowerCase()))}
      />
      <PlainCard styles={cardStyles}>
        <div css={rowStyles}>
          <ShortText styles={labelStyles}>kode course title:</ShortText>
          <ShortText styles={courseTitleStyles}>
            {selectedRow.kodeCourseTitle}
          </ShortText>
        </div>
        <div css={rowStyles}>
          <ShortText styles={labelStyles}>select tag</ShortText>
          <Select
            id="tags"
            instanceId="tags"
            css={reactSelectStyles}
            classNamePrefix="select"
            name="tags"
            value={selectedRow.courseType}
            options={completionTypeList}
            onChange={handleTagChange}
            isSearchable
            placeholder="choose tag"
          />
        </div>
        <div css={submitButtonContainerStyles}>
          <Button
            type="submit"
            size="M"
            color="LIGHT_BLUE"
            onClick={handleSubmit}
          >
            update
          </Button>
        </div>
      </PlainCard>
    </Container>
  );
};

Tags.propTypes = {
  handleSyncCourses: PropTypes.func.isRequired,
  selectedRow: PropTypes.shape({
    id: PropTypes.number,
    kodeCourseTitle: PropTypes.string,
    courseType: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  }).isRequired,
  setSelectedRow: PropTypes.func.isRequired,
  courseData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Tags;
